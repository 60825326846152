import { formatDate } from "../../../../utils"
import { getCityDisplayName } from "../../../../utils/cities"
import Config from '../../../../config';
const BASE_URL = Config.BASE_URL;


export const WebsiteSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Onsitego",
    "alternateName": "Onsitego | One-Stop Solution for All Your Devices - Protection Plans, Repair & Maintenance Services",
    "url": "https://onsitego.com/",
    "logo": "https://d2js0267gg4oqb.cloudfront.net/_next/static/media/onsite-logo-inverse.8190a3419a1359a4a0b5ff4aa42b6dfe.png",
    "sameAs": [
      "https://facebook.com/onsitego",
      "https://twitter.com/onsitego",
      "https://www.instagram.com/onsitego/",
      "https://www.youtube.com/c/Onsitego_official",
      "https://www.linkedin.com/company/onsitego"
    ],			
      "Review":[
      {	
      "@type": "Review",
          "url": "https://www.business-standard.com/article/technology/laptop-troubles-in-wfh-over-82-are-hardware-related-says-report-121032700765_1.html",
          "author": {
            "@type": "Person",
            "name": "BS Web Team"
          },
          "publisher": {
            "@type": "Organization",
            "name": "BusinessStandard",
            "sameAs": "https://www.business-standard.com/"
          },
          "datePublished": "2021-03-27T18:22",
          "description": "More than 82 percent of troubles with laptops are hardware-related and just 18 percent of them are software related, according to a report by Onsitego",
          "inLanguage": "en"
        },
        {
          "@type": "Review",
          "url": "https://www.moneycontrol.com/news/business/onsitego-sees-rise-in-demand-for-repair-servicing-of-appliances-6264591.html",
          "author": {
            "@type": "Person",
            "name": "M SARASWATHY"
          },
          "publisher": {
            "@type": "Organization",
            "name": "moneycontrol",
            "sameAs": "https://www.moneycontrol.com/"
          },
          "datePublished": "2020-08-23T20:37",
          "description": "Onsitego sees rise in demand for repair, servicing of appliances.",
          "inLanguage": "en"
        }
       ],
      
      "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.9",
          "bestRating": "5",
          "worstRating": "1",
          "ratingCount": "5123"
  }			
  }
                        
  
  export const FaqEntities =  (props) => props.data.faqs.map((item)=>{
    return {
      "@type": "Question",
      "name": item.title,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.answer
      }
    }
   }
  )
  
  export const BreadCrumbEntities = (props) => props.data.breadcrumb.map((item, index)=>{
    if(item==='Home')
    {
      return {
        "@type": "ListItem", 
        "position": index+1, 
        "name": item,
        "item": BASE_URL
      }    
    }
    else if(props.data.breadcrumb.length===3 && index===1)
    {
      return {
        "@type": "ListItem", 
        "position": index+1, 
        "name": item,
        "item": BASE_URL + "?city=" + item
      }
    }
    else{
      return {
        "@type": "ListItem", 
        "position": index+1, 
        "name": item,
        "item": BASE_URL + props.data.slug_mapping?.slug
      }
    }
  })
  
  export const category_type = (props) => Array.from(new Set(props.instaplans.length!==0 ? props.instaplans.map((item)=>{
    return item.category_type
  }):[]));
  
  
  
  export const LocalBusinessEntities = (props) => category_type(props).map((item) => {
    const list = props.instaplans.filter(plan => plan.category_type === item).map((plan) => {
      return {
        "@type": "Offer",
        "price": plan.price ? plan.price.toString() : "",
        "priceCurrency": "INR",
        "itemOffered": {
          "@type": "Service",
          "name": plan.title
        }
      }
    }
    )
    return {
      "@type": "OfferCatalog",
      "name": item,
      "itemListElement": list
    }
  })
  
  export const FaqSchema = (props) =>  {
    return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": FaqEntities(props)
    }
  }
  
  export const BreadCrumbSchema  = (props) => {return {
    "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": BreadCrumbEntities(props)
    }
  }
  
  export const LocalBusinessSchema = (props) => { return {
    "@context": "https://schema.org/",
    "@type": "Service",
    "serviceType": props.data.breadcrumb.length===3 ? props.data.breadcrumb[2] : "",
    "provider": {
      "@type": "LocalBusiness",
      "name": props.data.breadcrumb.length===3 ? props.data.breadcrumb[2] : ""
    },
    "areaServed": props.data.slug_mapping.cities_covered && props.data.slug_mapping.cities_covered.map((item)=>{
      return {
        "@type": "City",
        "name": getCityDisplayName(item.name)
      }
    }),
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name":  props.data.breadcrumb.length===3 ? props.data.breadcrumb[2] : "",
      "itemListElement": LocalBusinessEntities(props)
    }  
    }
  }
  
  export const ProductSchema = (props) => props.instaplans.length!==0 && props.instaplans.map((plan) => {
    return {
    "@context": "https://schema.org/", 
    "@type": "Product", 
    "name": plan.title,
    "image": plan.category_image,
    "description": plan.title_description,
    "brand": props.data.page_meta.popularBrands?.brands && props.data.page_meta.popularBrands?.brands.map((item)=>{
      return {
        "@type": "Brand",
        "name": item
      }
    }),
    "sku": plan.display_title,
    "gtin8": "",
    "offers": {
      "@type": "Offer",
      "priceCurrency": "INR",
      "price": plan.price ? plan.price.toString() : "",
      "availability": "https://schema.org/InStock",
      "itemCondition": "https://schema.org/NewCondition"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "9999"
    },  
    "review": props.data.testimonials.map((item)=>{
      return {
        "@type": "Review",
        "name": item.customer_name,
        "reviewBody": item.review,
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "4",
          "bestRating": "5",
          "worstRating": "1"
        },
        "datePublished": formatDate(new Date()),
        "author": {"@type": "Person", "name": item.customer_name}
      }
    })
    }
  })

  export const SproBreadCrumbSchema =  {
    "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home",
      "item": "https://onsitego.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Salespro App For Retailers",
      "item": "https://onsitego.com/salespro-app-for-retailers"  
    }]
  }

export const SproSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Onsitego",
  "url": "https://onsitego.com/",
  "logo": "https://d2js0267gg4oqb.cloudfront.net/_next/static/media/onsite-logo-inverse.8190a3419a1359a4a0b5ff4aa42b6dfe.png",
  "sameAs": [
    "https://facebook.com/onsitego",
    "https://twitter.com/onsitego",
    "https://www.instagram.com/onsitego/",
    "https://www.youtube.com/c/Onsitego_official",
    "https://www.linkedin.com/company/onsitego"
  ]
}

export const SproProductSchema = {
  "@context": "https://schema.org/", 
  "@type": "review", 
  "name": "Salespro",
  "description": "",
  "review": [{
    "@type": "Review",
    "datePublished":"2023-06-21",
    "name": "Sri Balaji retail, Delhi",
    "video": {
        "@type": "VideoObject",
        "url": "https://www.youtube.com/watch?v=6_-n2PeUC00&list=PL5b5o8zkv_TAWd9Defo6g3OgwbjV_r058&index=1"
      },
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "5"
    },
    "author": {"@type": "Person", "name": "Tarun"}
  },{
    "@type": "Review",
    "datePublished":"2023-07-11",
    "name": "R G cellular, Kolkata",
    "video": {
        "@type": "VideoObject",
        "url": "https://www.youtube.com/watch?v=0_4XgVTFpLY&list=PL5b5o8zkv_TAWd9Defo6g3OgwbjV_r058&index=2"
      },
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "5"
    },
    "author": {"@type": "Person", "name": "Ravi Gupta"}
  },{
    "@type": "Review",
    "datePublished":"2023-07-17",
    "name": "A J Marketing Place, Kolkata",
    "video": {
        "@type": "VideoObject",
        "url": "https://www.youtube.com/watch?v=c1Il-4kw3x0&list=PL5b5o8zkv_TAWd9Defo6g3OgwbjV_r058&index=3"
      },
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "5"
    },
    "author": {"@type": "Person", "name": "Avinash Agarwal"}
  },{
    "@type": "Review",
    "datePublished":"2023-07-20",
    "name": "Heartbeat, Kolkata",
    "video": {
        "@type": "VideoObject",
        "url": "https://www.youtube.com/watch?v=3IXDTrLBxL8&list=PL5b5o8zkv_TAWd9Defo6g3OgwbjV_r058&index=4"
      },
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "5"
    },
    "author": {"@type": "Person", "name": "Srinivas Sadani"}
  }]
}

export const SproFaqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is Salespro?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Salespro is Onsitego’s partner app where you can sell Onsitego’s device care plans to your customers. We have different plans for a wide range of digital devices and home appliances. You earn a margin on every plan you sell and we have regular contests that give you a chance to win exciting prizes like international trips."
    }
  },{
    "@type": "Question",
    "name": "Who can register and how can I register?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Anyone with a registered business can use Salespro to offer Onsitego plans. All you have to do is download the app and register on it."
    }
  },{
    "@type": "Question",
    "name": "When will my margins be settled?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Margins are settled immediately and they are credited to your Salespro wallet."
    }
  },{
    "@type": "Question",
    "name": "In case of issues, how can I get it resolved?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "It’s very easy to get in touch with us if you need any help. Call us on 022 - 6216 4881 or email us on salesprosupport@onsite.co.in. All issues are resolved in 12 hours."
    }
  },{
    "@type": "Question",
    "name": "How can my customers raise a claim?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You can raise a repair request on behalf of your customers via Salespro or your customers can directly get in touch with us. It’s very easy to raise a repair request on our website or our app. Your customers can also chat with us on our WhatsApp number 99205 99206."
    }
  },{
    "@type": "Question",
    "name": "What kind of plans can I sell?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We offer a wide range of plans for digital devices and home appliances. Our extended warranty is a popular plan as it offers a replacement guarantee in case we can’t repair a device. We have other plans like Spills and Drops Plan and Screen Protection Plan. You can see the full list of plans on your app dashboard."
    }
  }
  ]
}